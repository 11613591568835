import myMoney from '../assets/projects/myMoney.PNG'
import calculatorApp from "../assets/projects/calculatorApp.PNG";
import videoPlayer from "../assets/projects/videoPlayer.PNG";
import TyperTracker from "../assets/projects/TyperTracker.PNG";
import GoldenRuleCC from "../assets/projects/GoldenRuleCC.PNG";
import PaintingWithATwist from "../assets/projects/PaintingWithATwist.PNG";


export const data = [
  {
    id: 1,
    name: "React Expense Tracker",
    image: myMoney,
    github: "",
    live: "https://mymoney-97cb2.firebaseapp.com",
  },
  {
    id: 2,
    name: "React Calculator App",
    image: calculatorApp,
    github: "",
    live: "https://calculator-app-57878.firebaseapp.com",
  },
  {
    id: 3,
    name: "Youtube Clone",
    image: videoPlayer,
    github: "",
    live: "https://video-player-site-83e07.web.app/",
  },
  {
    id: 4,
    name: "Typing Tracker",
    image: TyperTracker,
    github: "",
    live: "https://typertracker-f676c.firebaseapp.com/",
  },
  {
    id: 5,
    name: "Construction Website",
    image: GoldenRuleCC,
    github: "",
    live: "https://goldenrulecc.com",
  },
  {
    id: 6,
    name: "Painting With A Twist",
    image: PaintingWithATwist,
    github: "",
    live: "https://paintingwithatwist-45eba.firebaseapp.com/",
  },
];