import React, { useEffect, useState } from "react";
import {
  FaBars,
  FaTimes,
  FaGithub,
  FaLinkedin,
  FaFacebook,
  FaLinkedinIn,
} from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { BsFillPersonLinesFill } from "react-icons/bs";
import Logo from "../assets/projects/BryGuyDevelops.PNG";
import Checkmark from "../assets/checkmark.svg";
import { Link } from "react-scroll";

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const handleClick = (text) => {
    setNav(!nav);
    setTitle(text);
  };
  const handleTitle = (text) => setTitle(text);
  const [emailCopied, setEmailCopied] = useState(false);

  const handleEmailClick = () => {
    navigator.clipboard
      .writeText("24bryan24@gmail.com")
      .then(() => {
        setEmailCopied(true);
      })
      .catch(() => {
        alert("Nothing has been copied to the clipboard");
      });

    setTimeout(() => {
      setEmailCopied(false);
    }, 2000);
  };

  const [title, setTitle] = useState("Bryan's Resume");

  const hoverResume = () => {
    setEmailCopied(false);
  };

  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <div className="fixed w-full h-[80px] flex justify-between items-center px-4 bg-[#0a192f] text-gray-300">
      <div className="font-bold text-xl text-sky-400 font-[Lobster] -translate-x-4">
        <img src={Logo} alt="Logo Image" style={{ width: "200px" }} />
      </div>
      {/* menu */}
      <ul className="hidden md:flex">
        <li>
          <Link
            to="home"
            smooth={true}
            duration={500}
            onClick={() => handleTitle("Welcome!")}
          >
            Home
          </Link>
        </li>
        <li>
          <Link
            to="about"
            smooth={true}
            duration={500}
            onClick={() => handleTitle("About Me")}
          >
            About
          </Link>
        </li>
        <li>
          <Link
            to="skills"
            smooth={true}
            offset={-50}
            duration={500}
            onClick={() => handleTitle("My Skills")}
          >
            Skills
          </Link>
        </li>
        <li>
          <Link
            to="work"
            smooth={true}
            offset={-100}
            duration={500}
            onClick={() => handleTitle("My Work")}
          >
            Work
          </Link>
        </li>
        <li>
          <Link
            to="contact"
            smooth={true}
            offset={50}
            duration={500}
            onClick={() => handleTitle("Contact Me!")}
          >
            Contact
          </Link>
        </li>
      </ul>
      {/* Hamburger */}
      <div onClick={handleClick} className="md:hidden z-10 cursor-pointer">
        {!nav ? <FaBars /> : <FaTimes />}
      </div>
      {/* Mobile menu */}
      <ul
        className={
          !nav
            ? "hidden"
            : "absolute top-0 left-0 w-full h-screen bg-[#0a192f] flex flex-col justify-center items-center"
        }
      >
        <li className="py-6 text-4xl">
          <Link onClick={handleClick} to="home" smooth={true} duration={500}>
            Home
          </Link>
        </li>
        <li className="py-6 text-4xl">
          {" "}
          <Link onClick={handleClick} to="about" smooth={true} duration={500}>
            About
          </Link>
        </li>
        <li className="py-6 text-4xl">
          {" "}
          <Link onClick={handleClick} to="skills" smooth={true} duration={500}>
            Skills
          </Link>
        </li>
        <li className="py-6 text-4xl">
          {" "}
          <Link
            onClick={handleClick}
            to="work"
            smooth={true}
            offset={-75}
            duration={500}
          >
            Work
          </Link>
        </li>
        <li className="py-6 text-4xl">
          {" "}
          <Link
            onClick={handleClick}
            to="contact"
            smooth={true}
            offset={55}
            duration={500}
          >
            Contact
          </Link>
        </li>
      </ul>
      {/* Social icons */}
      <div className="hidden lg:flex fixed flex-col top-[35%] left-0">
        <ul>
          <li className="w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-blue-600">
            <a
              className="flex justify-between items-center w-full text-gray-300"
              href="https://www.linkedin.com/in/bryan-heilman-231186133
"
            >
              Linkedin <FaLinkedin size={30} />
            </a>
          </li>
          <li className="w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#333333]">
            <a
              className="flex justify-between items-center w-full text-gray-300"
              href="https://github.com/24bryan24"
            >
              Github <FaGithub size={30} />
            </a>
          </li>
          <li
            onClick={handleEmailClick}
            className="group relative w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#6fc2b0]"
          >
            {/* <a
              className="flex justify-between items-center w-full text-gray-300"
              href="/"
            > */}
            Email
            {emailCopied ? (
              <div className="relative">
                <img className="scale-[0.75]" src={Checkmark} />
                <div className="w-16 h-10 bg-transparent absolute top-16 group-hover:-left-2 duration-300 left-[5rem] text-[14px]">
                  copied to clipboard!
                </div>
              </div>
            ) : (
              <HiOutlineMail className="duration-300" size={30} />
            )}
            {/* </a>
             */}
          </li>
          <li
            onMouseEnter={hoverResume}
            className="w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#565f69]"
          >
            <a
              className="flex justify-between items-center w-full text-gray-300"
              href="https://docs.google.com/document/d/e/2PACX-1vTwTDLZEtNJUfJ9C1E6YGiHub4BU0FsSEXCOTu54J3d6aiXgUGWHeCbwzTEAYujmf88YffycFN7xJXw/pub"
            >
              Resume <BsFillPersonLinesFill size={30} />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
